<template>
  <div :class="'iq-sidebar  sidebar-default ' + variant">
    <div class="iq-sidebar-logo d-flex align-items-end justify-content-between">
      <a href="/" class="header-logo">
        <img src="@/assets/images/Analiza.svg" class="img-fluid rounded-normal light-logo" alt="logo">
        <img src="@/assets/images/Analiza.svg" class="img-fluid rounded-normal d-none sidebar-light-img" alt="logo">
        <span>{{ app_name }}</span>
      </a>
      <div class="side-menu-bt-sidebar-1">
        <svg xmlns="http://www.w3.org/2000/svg" class="text-light wrapper-menu" width="30" fill="none"
             viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </div>
    </div>
    <div class="data-scrollbar" data-scroll="1" id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu">
        <ul id="iq-sidebar-toggle" class="side-menu">
          <li :class=" checkActive('layout.dashboard1') ? 'sidebar-layout active' : 'sidebar-layout'">
            <router-link :to="{ name: 'layout.dashboard1'}" class="svg-icon">
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="h-6 w-6" fill="none"
                     viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"/>
                </svg>
              </i>
              <span class="ml-2">Dashboard</span>
              <p class="mb-0 w-10 badge badge-pill badge-primary">6</p>
            </router-link>
          </li>
          <li v-for="menu in auth_user.menus" :class="(menu.is_parent) ? 'px-3 pt-3 pb-2' : ( checkActive(menu.name)  ? 'sidebar-layout active' : 'sidebar-layout' ) ">
            <span v-if="menu.is_parent" class="text-uppercase small font-weight-bold" v-text="menu.text"></span>
            <router-link v-else :to="{name :menu.name}" class="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart-fill" viewBox="0 0 16 16">
                <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2z"/>
              </svg>
              <span class="ml-2" v-text="menu.text"></span>
            </router-link>
          </li>
<!--          <li v-for="submenu in menu.children" :class="checkActive(submenu.name) ? 'sidebar-layout active' : 'sidebar-layout'">
            <router-link :to="{name :submenu.name}" class="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart-fill" viewBox="0 0 16 16">
                <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2z"/>
              </svg>
              <span class="ml-2" v-text="submenu.text"></span>
            </router-link>
          </li>-->
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import {core} from '@/config/pluginInit'

export default {
  name: "SidebarStyle",
  props: {
    items: Array,
    variant: {type: String},
    auth_user: Array | Object
  },
  data() {
    return {
      app_name: process.env.VUE_APP_NAME,
      homeurl: '',
      dashboards: ['layout.dashboard1', 'layout.dashboard2', 'layout.dashboard3', 'layout.customer', 'layout.product', 'layout.order', 'layout.calendar'],
      app: ['app.chat', 'app.todo'],
      charts: ['chart.amchart', 'chart.apexchart', 'chart.highchart', 'chart.morrischart',],
      user: ['app.user-profile', 'app.user-add', 'app.user-list'],
      ui: ['Ui.avatars', 'Ui.alerts', 'Ui.badges', 'Ui.breadcrumbs', 'Ui.buttons', 'Ui.button-groups', 'Ui.boxshadows', 'Ui.colors', 'Ui.cards', 'Ui.carousels', 'Ui.grids', 'Ui.helper-classes', 'Ui.images', 'Ui.list-groups', 'Ui.media-objects', 'Ui.modals', 'Ui.notifications'
        , 'Ui.paginations', 'Ui.popovers', 'Ui.progressbars', 'Ui.typographys', 'Ui.tabs', 'Ui.tooltips', 'Ui.Embed-videos'],
      auth: ['auth.login', 'auth.register', 'auth.recover-password', 'auth.confirm-mail', 'auth.lock-screen'],
      pricing: ['price.pay', 'price.pay2'],
      icon: ['icon.dripicon', 'icon.fontawsome', 'icon.lineawsome', 'icon.remixicon',],
      error: ['error.404', 'error.500'],
      formControls: ['controls.form-checkbox', 'controls.form-layout', 'controls.form-input', 'controls.form-radio', 'controls.form-switch', 'controls.form-textarea', 'controls.form-validation',],
      formWidget: ['widget.form-datepicker', 'widget.form-file-uploader', 'widget.form-quill',],
      table: ['table.basic-table', 'table.data-table', 'table.edit-table',],
      timeline: ['time.line', 'time.line1'],
      extraPages: ['price.pay', 'pages.maintenance', 'pages.comingsoon', 'pages.invoices', 'pages.subscribers', 'pages.faq', 'pages.blank-page', 'pages.icon'],
      pages: []
    }
  },
  mounted() {
    core.SmoothScrollbar()
    const urlParams = new URLSearchParams(window.location.search);
    const sidebar = urlParams.get('sidebar');
    if (sidebar !== null) {
      this.variant = ''
      switch (sidebar) {
        case "0":
          this.variant = 'sidebar-dark'
          break;
        case "1":
          this.variant = 'sidebar-light'
          break;
        default:
          this.variant = ''
          break;
      }
    }
  },
  destroyed() {
    core.SmoothScrollbar()
  },
  computed: {
    ...mapGetters({
      logo: 'darklogo',
      sidelogo: 'logo',
    })
  },
  methods: {
    checkActive(route) {
      if (this.$route.name == route) {
        return true;
      }
      if (route.includes(this.$route.name)) {
        return true
      }
    },
    miniSidebar() {
      core.triggerSet()
    },
    showCollapse(collapseId) {
      document.getElementById(collapseId).closest('li').classList.add('active')
    },
    hideCollapse() {
      const list = document.querySelector('.iq-sidebar-menu').querySelectorAll('.submenu')
      Array.from(list, elem => {
        if (elem.closest('.active') !== null && elem.closest('.active') !== undefined) {
          elem.closest('.active').classList.remove('active')
        }
      })
    }
  },
}
</script>

