<template>
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom">
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <!-- <div class="iq-navbar-logo d-flex align-items-center justify-content-between" @click="miniSidebar"> <i class="ri-menu-line wrapper-menu"></i>
          <router-link :to="{name: 'layout.dashboard1'}" class="header-logo"> <img :src="sidelogo" class="img-fluid rounded-normal" alt="logo"> </router-link>
        </div>
        <div class="iq-search-bar device-search m-auto">
          <form action="#" class="searchbox"> <a class="search-link" href="#"><i class="ri-search-line"></i></a>
            <input type="text" class="text search-input" placeholder="Search here..."> </form>
        </div> -->
        <div class="side-menu-bt-sidebar">
          <svg xmlns="http://www.w3.org/2000/svg" class="text-secondary wrapper-menu" width="30" fill="none"
               viewBox="0 0 24 24" stroke="currentColor" @click="miniSidebar">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
          </svg>
        </div>
        <div class="d-flex align-items-center">
          <ModeSwitch/>
          <b-navbar-toggle target="nav-collapse" class="">
            <svg xmlns="http://www.w3.org/2000/svg" class="text-secondary" width="30" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"/>
            </svg>
          </b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <ul class="navbar-nav ml-auto navbar-list align-items-center">
              <li class="nav-item nav-icon dropdown" v-nav-toggle>
                <a href="#" class="search-toggle dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="h-6 w-6 text-secondary"
                       fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>
                  </svg>
                  <span class="bg-primary"></span>
                </a>
                <div class="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <div class="card shadow-none m-0 border-0">
                    <div class="p-3 card-header-border">
                      <h6 class="text-center">
                        Notifications
                      </h6>
                    </div>
                    <div class="card-body overflow-auto card-header-border p-0 card-body-list"
                         style="max-height: 500px;">
                      <ul class="dropdown-menu-1 overflow-auto list-style-1 mb-0">
<!--                        <li class="dropdown-item-1 float-none p-3">
                          <div class="list-item d-flex justify-content-start align-items-start">
                            <div class="avatar">
                              <div class="avatar-img avatar-danger avatar-20">
                                                            <span>
                                                                <svg class="icon line" width="30" height="30"
                                                                     id="cart-alt1" stroke="white"
                                                                     xmlns="http://www.w3.org/2000/svg"
                                                                     viewBox="0 0 24 24"><path
                                                                    d="M3,3H5.32a1,1,0,0,1,.93.63L10,13,8.72,15.55A1,1,0,0,0,9.62,17H19"
                                                                    style="fill: none;stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></path><polyline
                                                                    points="10 13 18.2 13 21 6"
                                                                    style="fill: none;stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></polyline><line
                                                                    x1="20.8" y1="6" x2="7.2" y2="6"
                                                                    style="fill: none;stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></line><circle
                                                                    cx="10.5" cy="20.5" r="0.5"
                                                                    style="fill: none;stroke-miterlimit: 10; stroke-width: 2;"></circle><circle
                                                                    cx="16.5" cy="20.5" r="0.5"
                                                                    style="fill: none;stroke-miterlimit: 10; stroke-width: 2;"></circle></svg>
                                                            </span>
                              </div>
                            </div>
                            <div class="list-style-detail ml-2 mr-2">
                              <h6 class="font-weight-bold">Your order is placed</h6>
                              <p class="m-0">
                                <small class="text-secondary">If several languages coalesce</small>
                              </p>
                              <p class="m-0">
                                <small class="text-secondary">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="text-secondary mr-1" width="15"
                                       fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                  </svg>
                                  3 hours ago</small>
                              </p>
                            </div>
                          </div>
                        </li>
                        <li class="dropdown-item-1 float-none p-3">
                          <div class="list-item d-flex justify-content-start align-items-start">
                            <div class="avatar">
                              <div class="avatar-img avatar-success avatar-20">
                                <span><img class="avatar is-squared rounded-circle"
                                           src="@/assets/images/user/2.jpg"></span>
                              </div>
                            </div>
                            <div class="list-style-detail ml-2 mr-2">
                              <h6 class="font-weight-bold">New message form cate</h6>
                              <p class="m-0">
                                <small class="text-secondary">You have 3 unreded messages</small>
                              </p>
                              <p class="m-0">
                                <small class="text-secondary">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="text-secondary mr-1" width="15"
                                       fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                  </svg>
                                  5 hours ago</small>
                              </p>
                            </div>
                          </div>
                        </li>
                        <li class="dropdown-item-1 float-none p-3">
                          <div class="list-item d-flex justify-content-start align-items-start">
                            <div class="avatar">
                              <div class="avatar-img avatar-warning avatar-20">
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                 version="1.1" width="30" height="30" stroke="white"
                                                                 id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512"
                                                                 style="enable-background:new 0 0 512 512;"
                                                                 xml:space="preserve">
                                                            <g>
                                                                <g>
                                                                    <path
                                                                        d="M386.689,304.403c-35.587,0-64.538,28.951-64.538,64.538s28.951,64.538,64.538,64.538    c35.593,0,64.538-28.951,64.538-64.538S422.276,304.403,386.689,304.403z M386.689,401.21c-17.796,0-32.269-14.473-32.269-32.269    c0-17.796,14.473-32.269,32.269-32.269c17.796,0,32.269,14.473,32.269,32.269C418.958,386.738,404.485,401.21,386.689,401.21z"/>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <path
                                                                        d="M166.185,304.403c-35.587,0-64.538,28.951-64.538,64.538s28.951,64.538,64.538,64.538s64.538-28.951,64.538-64.538    S201.772,304.403,166.185,304.403z M166.185,401.21c-17.796,0-32.269-14.473-32.269-32.269c0-17.796,14.473-32.269,32.269-32.269    c17.791,0,32.269,14.473,32.269,32.269C198.454,386.738,183.981,401.21,166.185,401.21z"/>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <path
                                                                        d="M430.15,119.675c-2.743-5.448-8.32-8.885-14.419-8.885h-84.975v32.269h75.025l43.934,87.384l28.838-14.5L430.15,119.675z"/>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <rect x="216.202" y="353.345" width="122.084"
                                                                          height="32.269"/>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <path
                                                                        d="M117.781,353.345H61.849c-8.912,0-16.134,7.223-16.134,16.134c0,8.912,7.223,16.134,16.134,16.134h55.933    c8.912,0,16.134-7.223,16.134-16.134C133.916,360.567,126.693,353.345,117.781,353.345z"/>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <path
                                                                        d="M508.612,254.709l-31.736-40.874c-3.049-3.937-7.755-6.239-12.741-6.239H346.891V94.655    c0-8.912-7.223-16.134-16.134-16.134H61.849c-8.912,0-16.134,7.223-16.134,16.134s7.223,16.134,16.134,16.134h252.773v112.941    c0,8.912,7.223,16.134,16.134,16.134h125.478l23.497,30.268v83.211h-44.639c-8.912,0-16.134,7.223-16.134,16.134    c0,8.912,7.223,16.134,16.134,16.134h60.773c8.912,0,16.134-7.223,16.135-16.134V264.605    C512,261.023,510.806,257.538,508.612,254.709z"/>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <path
                                                                        d="M116.706,271.597H42.487c-8.912,0-16.134,7.223-16.134,16.134c0,8.912,7.223,16.134,16.134,16.134h74.218    c8.912,0,16.134-7.223,16.134-16.134C132.84,278.82,125.617,271.597,116.706,271.597z"/>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <path
                                                                        d="M153.815,208.134H16.134C7.223,208.134,0,215.357,0,224.269s7.223,16.134,16.134,16.134h137.681    c8.912,0,16.134-7.223,16.134-16.134S162.727,208.134,153.815,208.134z"/>
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <path
                                                                        d="M180.168,144.672H42.487c-8.912,0-16.134,7.223-16.134,16.134c0,8.912,7.223,16.134,16.134,16.134h137.681    c8.912,0,16.134-7.223,16.134-16.134C196.303,151.895,189.08,144.672,180.168,144.672z"/>
                                                                </g>
                                                            </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g> </svg>
                                                        </span>
                              </div>
                            </div>
                            <div class="list-style-detail ml-2 mr-2">
                              <h6 class="font-weight-bold">Your item is shipped</h6>
                              <p class="m-0">
                                <small class="text-secondary">You got new order of goods</small>
                              </p>
                              <p class="m-0">
                                <small class="text-secondary">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="text-secondary mr-1" width="15"
                                       fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                  </svg>
                                  5 hours ago</small>
                              </p>
                            </div>
                          </div>
                        </li>-->
                      </ul>
                    </div>
                    <div class="card-footer text-muted p-3">
                      <p class="mb-0 text-primary text-center font-weight-bold">Show all notifications</p>
                    </div>
                  </div>
                </div>
              </li>

              <li class="nav-item nav-icon search-content" v-nav-toggle>
                <a href="#" class="search-toggle rounded dropdown-toggle" id="dropdownSearch" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">
                  <svg class="svg-icon text-secondary" id="h-suns" height="25" width="25"
                       xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                  </svg>
                </a>
                <div class="iq-search-bar iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownSearch">
                  <form action="#" class="searchbox p-2">
                    <div class="form-group mb-0 position-relative">
                      <input type="text" class="text search-input font-size-12" placeholder="type here to search...">
                      <a href="#" class="search-link">
                        <svg xmlns="http://www.w3.org/2000/svg" class="" width="20" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                        </svg>
                      </a>
                    </div>
                  </form>
                </div>
              </li>
              <li class="nav-item nav-icon dropdown" v-nav-toggle>
                <a href="#" class="nav-item nav-icon dropdown-toggle pr-0 search-toggle" id="dropdownMenuButton"
                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img :src="auth_user.auth.avatar" class="img-fluid avatar-rounded" alt="user">
                  <span class="mb-0 ml-2 user-name">{{
                      auth_user.auth.name
                    }}</span>
                </a>
                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
<!--                  <li class="dropdown-item d-flex svg-icon">
                    <svg class="svg-icon mr-0 text-secondary" id="h-01-p" width="20" xmlns="http://www.w3.org/2000/svg"
                         fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                    <router-link :to="{name: 'app.user-profile'}">My Profile</router-link>
                  </li>
                  <li class="dropdown-item d-flex svg-icon">
                    <svg class="svg-icon mr-0 text-secondary" id="h-02-p" width="20" xmlns="http://www.w3.org/2000/svg"
                         fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                    </svg>
                    <router-link :to="{name: 'app.user-profile-edit'}">Edit Profile</router-link>
                  </li>
                  <li class="dropdown-item d-flex svg-icon">
                    <svg class="svg-icon mr-0 text-secondary" id="h-03-p" width="20" xmlns="http://www.w3.org/2000/svg"
                         fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"/>
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                    </svg>
                    <router-link :to="{name: 'app.user-Account-setting'}">Account Settings</router-link>
                  </li>
                  <li class="dropdown-item d-flex svg-icon">
                    <svg class="svg-icon mr-0 text-secondary" id="h-04-p" width="20" xmlns="http://www.w3.org/2000/svg"
                         fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"/>
                    </svg>
                    <router-link :to="{name: 'app.user-privacy-setting'}">Privacy Settings</router-link>
                  </li>
                  <li class="dropdown-item  d-flex svg-icon border-top">
                    <svg class="svg-icon mr-0 text-secondary" id="h-05-p" width="20" xmlns="http://www.w3.org/2000/svg"
                         fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"/>
                    </svg>
                    <router-link :to="{ name: 'auth.login'}">Logout</router-link>
                  </li>-->
                </ul>
              </li>
              <!-- <li class="nav-item nav-icon search-content" v-nav-toggle>
                <a href="#" class="search-toggle rounded dropdown-toggle" id="dropdownSearch" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <svg class="svg-icon text-primary" id="h-suns" height="20" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /> </svg>
                </a>
                <div class="iq-search-bar iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownSearch">
                  <form action="#" class="searchbox p-2">
                    <div class="form-group mb-0 position-relative">
                      <input type="text" class="text search-input font-size-12" placeholder="type here to search..."> <a href="#" class="search-link"><i class="las la-search"></i></a> </div>
                  </form>
                </div>
              </li>
              <li class="nav-item nav-icon dropdown" v-nav-toggle>
                <a href="javascript:void(0)" class="search-toggle dropdown-toggle" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <svg class="svg-icon feather feather-mail text-primary" id="iq-mail-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                    <polyline points="22,6 12,13 2,6"></polyline>
                  </svg> <span class="bg-primary"></span> </a>
                <div class="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton2">
                  <div class="card shadow-none m-0 border-0">
                    <div class="card-body p-0 ">
                      <div class="cust-title p-3">
                        <h5 class="mb-0">All Messages</h5> </div>
                      <div class="p-3">
                        <a href="#" class="iq-sub-card">
                          <div class="media align-items-center">
                            <div class=""> <img class="avatar-40 rounded-small" src="@/assets/images/user/1.jpg" alt="01"> </div>
                            <div class="media-body ml-3">
                              <h6 class="mb-0">Barry Eiqa Watson <small class="badge badge-success float-right">New</small></h6> <small class="float-left font-size-12">12:00 PM</small> </div>
                          </div>
                        </a>
                        <a href="#" class="iq-sub-card">
                          <div class="media align-items-center">
                            <div class=""> <img class="avatar-40 rounded-small" src="@/assets/images/user/2.jpg" alt="02"> </div>
                            <div class="media-body ml-3">
                              <h6 class="mb-0 ">Lorem Ipsum Watson</h6> <small class="float-left font-size-12">20 Apr</small> </div>
                          </div>
                        </a>
                        <a href="#" class="iq-sub-card">
                          <div class="media align-items-center">
                            <div class=""> <img class="avatar-40 rounded-small" src="@/assets/images/user/3.jpg" alt="03"> </div>
                            <div class="media-body ml-3">
                              <h6 class="mb-0 ">Why do we use it? <small class="badge badge-success float-right">New</small></h6> <small class="float-left font-size-12">1:24 PM</small> </div>
                          </div>
                        </a>
                        <a href="#" class="iq-sub-card">
                          <div class="media align-items-center">
                            <div class=""> <img class="avatar-40 rounded-small" src="@/assets/images/user/4.jpg" alt="04"> </div>
                            <div class="media-body ml-3">
                              <h6 class="mb-0">Variations Passages <small class="badge badge-success float-right">New</small></h6> <small class="float-left font-size-12">5:45 PM</small> </div>
                          </div>
                        </a>
                        <a href="#" class="iq-sub-card">
                          <div class="media align-items-center">
                            <div class=""> <img class="avatar-40 rounded-small" src="@/assets/images/user/5.jpg" alt="05"> </div>
                            <div class="media-body ml-3">
                              <h6 class="mb-0 ">Lorem Ipsum generators</h6> <small class="float-left font-size-12">1 day ago</small> </div>
                          </div>
                        </a>
                      </div> <a class="d-flex justify-content-center p-2 card-footer" href="#" role="button">
                                          View All
                                      </a> </div>
                  </div>
                </div>
              </li>
              <li class="nav-item nav-icon dropdown" v-nav-toggle>
                <a href="#" class=" search-toggle dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <svg class="feather feather-bell svg-icon text-primary" id="iq-bell-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                    <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                  </svg> <span class="bg-primary "></span> </a>
                <div class="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <div class="card shadow-none m-0 border-0">
                    <div class="card-body p-0 ">
                      <div class="cust-title p-3">
                        <h5 class="mb-0">All Notifications</h5> </div>
                      <div class="p-3">
                        <a href="#" class="iq-sub-card">
                          <div class="media align-items-center">
                            <div class=""> <img class="avatar-40 rounded-small" src="@/assets/images/user/1.jpg" alt="01"> </div>
                            <div class="media-body ml-3">
                              <h6 class="mb-0">Eiqa Watson Barry <small class="badge badge-success float-right">New</small></h6>
                              <p class="mb-0">95 MB</p>
                            </div>
                          </div>
                        </a>
                        <a href="#" class="iq-sub-card">
                          <div class="media align-items-center">
                            <div class=""> <img class="avatar-40 rounded-small" src="@/assets/images/user/2.jpg" alt="02"> </div>
                            <div class="media-body ml-3">
                              <h6 class="mb-0 ">New customer is join</h6>
                              <p class="mb-0">Cyst Barry</p>
                            </div>
                          </div>
                        </a>
                        <a href="#" class="iq-sub-card">
                          <div class="media align-items-center">
                            <div class=""> <img class="avatar-40 rounded-small" src="@/assets/images/user/3.jpg" alt="03"> </div>
                            <div class="media-body ml-3">
                              <h6 class="mb-0 ">Two customer is left</h6>
                              <p class="mb-0">Cyst Barry</p>
                            </div>
                          </div>
                        </a>
                        <a href="#" class="iq-sub-card">
                          <div class="media align-items-center">
                            <div class=""> <img class="avatar-40 rounded-small" src="@/assets/images/user/4.jpg" alt="04"> </div>
                            <div class="media-body ml-3">
                              <h6 class="mb-0 ">New Mail from Fenny <small class="badge badge-success float-right">New</small></h6>
                              <p class="mb-0">Cyst Barry</p>
                            </div>
                          </div>
                        </a>
                      </div> <a class="d-flex justify-content-center p-2 card-footer" href="#" role="button">
                                          View All
                                      </a> </div>
                  </div>
                </div>
              </li>
              <Fullscreen class="nav-item nav-icon dropdown full-screen" extraclass="nav-item nav-icon dropdown" />
              <li class="nav-item nav-icon dropdown" v-nav-toggle>
                <a class="nav-item nav-icon dropdown-toggle pr-0 search-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <img src="@/assets/images/user/1.jpg" class="img-fluid avatar-rounded" alt="user"> </a>
                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                  <li class="dropdown-item d-flex svg-icon">
                    <svg class="svg-icon mr-0 text-primary" id="h-01-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" /> </svg>
                    <router-link :to="{name: 'app.user-profile'}">My Profile</router-link>
                  </li>
                  <li class="dropdown-item d-flex svg-icon">
                    <svg class="svg-icon mr-0 text-primary" id="h-02-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" /> </svg>
                    <router-link :to="{name: 'app.user-profile-edit'}">Edit Profile</router-link>
                  </li>
                  <li class="dropdown-item d-flex svg-icon">
                    <svg class="svg-icon mr-0 text-primary" id="h-03-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /> </svg>
                    <router-link :to="{name: 'app.user-Account-setting'}">Account Settings</router-link>
                  </li>
                  <li class="dropdown-item d-flex svg-icon">
                    <svg class="svg-icon mr-0 text-primary" id="h-04-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" /> </svg>
                    <router-link :to="{name: 'app.user-privacy-setting'}">Privacy Settings</router-link>
                  </li>
                  <li class="dropdown-item  d-flex svg-icon border-top">
                    <svg class="svg-icon mr-0 text-primary" id="h-05-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" /> </svg>
                    <router-link :to="{ name: 'auth.login'}">Logout</router-link>
                  </li>
                </ul>
              </li> -->
            </ul>
          </b-collapse>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import {core} from '@/config/pluginInit'
// import Fullscreen from '../../../fullscreen/Fullscreen'
import {mapGetters} from 'vuex'

export default {
  props: ['auth_user'],
  name: "HeaderStyle1",
  // components: {  Fullscreen},
  computed: {
    ...mapGetters({
      logo: 'darklogo',
      sidelogo: 'logo',
    })
  },
  methods: {
    miniSidebar() {
      core.triggerSet()
    }
  },
  data() {
    return {
      extraPages: ['app.user-Account-setting', 'app.user-privacy-setting'],
      auth: ['auth.login'],
      user: ['app.user-profile', 'app.user-profile-edit']

    }
  },
  mounted() {
  }
}
</script>
