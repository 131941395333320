<template>
  <div id="loading">
    <div id="loading-center">
    </div>
  </div>
</template>
<script>
import {animation} from "@/config/pluginInit";

export default {
  props: ['is_logged'],
  name: 'Loader',
  watch: {
    is_logged: function (val) {
      if (this.is_logged === true) {
        this.hideLoad()
      }
    },
    $route: function () {
      this.init()
    }
  },
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {

    },
    hideLoad() {
      const load = document.getElementById('loading')
      load.classList.remove('d-none')
      window.scrollTo({top: 0, behavior: 'smooth'});
      animation.fadeIn(load, {duration: 20})
      setTimeout (() => {
        animation.fadeOut(load, { duration: 20 })
        load.classList.add('d-none')
      }, 1000)
    }
  }
}
</script>
