<template>
  <div>
    <Loader :is_logged="is_logged"/>
    <div class="wrapper" v-if="is_logged">
      <SidebarStyle :auth_user="auth_user" variant=''/>
      <HeaderStyle1 :auth_user="auth_user"/>
      <div class="content-page">
        <transition name="router-anim">
          <router-view :auth_user="auth_user"/>
        </transition>
      </div>
    </div>
    <FooterStyle/>
  </div>
</template>
<script>
import Loader from '@/components/loader/Loader'
import SidebarStyle from "@/components/partials/backend/SidebarStyle/SidebarStyle";
import HeaderStyle1 from "@/components/partials/backend/HeaderStyle/HeaderStyle1";
import FooterStyle from "@/components/partials/backend/FooterStyle/FooterStyle";

export default {
  name: 'Layout-2',
  components: {
    HeaderStyle1,
    FooterStyle,
    SidebarStyle,
    Loader
  },
  data() {
    return {
      is_logged: false,
      auth_user: []
    }
  },
  mounted() {
    this.getMenu()
  },
  methods: {
    getMenu() {
      this.$api.get('/api/menu/get-menu').then(res => {
        this.is_logged = true
        this.auth_user = res.data
      }).catch(err => {

      })
    },
  }

}
</script>
<style>
</style>
